// extracted by mini-css-extract-plugin
export var productinfo = "style-module--productinfo--b4be2";
export var sectionbody = "style-module--sectionbody--f5313";
export var sectionbodypaintingschedule73 = "style-module--sectionbodypaintingschedule73--149a6";
export var sectioncolorbody = "style-module--sectioncolorbody--b2e0d";
export var sectiondescriptionbody = "style-module--sectiondescriptionbody--ce3ca";
export var sectionsubtitle = "style-module--sectionsubtitle--b4873";
export var sectiontitle = "style-module--sectiontitle--50c7a";
export var techarea = "style-module--techarea--c9148";
export var techareavariantsnone = "style-module--techareavariantsnone--4df71";
export var techareavariantstwo = "style-module--techareavariantstwo--2f873";
export var techcell = "style-module--techcell--78cfc";
export var techheader = "style-module--techheader--05ec2";
export var techlabel = "style-module--techlabel--4aa8a";