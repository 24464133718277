import React, { Component } from "react"
import PropTypes from "prop-types"

import * as styles from './style.module.css'

import PaintHeader from "../paintcalculator/header"
import PaintTable from "../paintcalculator/painttable"
import ControlSelect from "../controls/select"


import calculatordata from "../../content/calculatordata.json"
import substratelabels from "../../content/substratelabels.json"

const apiurl = "https://dataset.boysencrm.com/paints/productinfo/";

class ProductPage extends Component {
	state = {
		productdata: null,
		technicaldata: null,
		calcdata: null,
		colordata: null,
		surfacetext:substratelabels[this.props.surfacelist[0].key],
		surfacecode:this.props.surfacelist[0].key
	};

	handleDetailChange = (newvalue) => {
		this.setState({
			surfacetext: substratelabels[this.props.surfacelist[newvalue].key],
			surfacecode: this.props.surfacelist[newvalue].key,
			calcdata: calculatordata[this.props.surfacelist[newvalue].key]
		});

/*
		fetch(apiurl+"../calculator/"+this.props.surfacelist[newvalue].key+".json")
			.then(res => res.json())
			.then(data => {
				console.log(data.calculator);
				this.setState({
					surfacetext: this.props.surfacelist[newvalue].name,
					surfacecode: this.props.surfacelist[newvalue].key,
					calcdata: data.calculator
				});
			});
*/
	}
	componentDidMount() {
		fetch(apiurl+"detail"+this.props.productID+".json")
			.then(res => res.json())
			.then(data => this.setState({productdata: data})
			);
		fetch(apiurl+"tech"+this.props.productID+".json")
			.then(res => res.json())
			.then(data => {
				this.setState({technicaldata: data});
			});
		fetch(apiurl+"color"+this.props.productID+".json")
			.then(res => res.json())
			.then(data => {
				this.setState({colordata: data});
			});
		this.setState({calcdata: calculatordata[this.props.surfacelist[0].key]});

/*
		fetch(apiurl+"../calculator/"+this.state.surfacecode+".json")
			.then(res => res.json())
			.then(data => {
				this.setState({calcdata: data.calculator});
			});
*/
	}

	render() {
		return (
			<div itemtype="https://schema.org/Product" itemscope>
				<meta itemprop="brand" content="Konstrukt" />
				<meta itemprop="name" content={this.props.pageTitle} />
				<img itemprop="image" src={this.props.image_product} className={"fullblock-desktop-only"} alt="" />
				<img itemprop="image" src={this.props.image_product_mobile} className={"fullblock-mobile-only"} alt="" />
				{
			  		(this.state.productdata) &&
				  	<div className={styles.productinfo}>
				  	{
				  		this.state.productdata.info.map((sectiondata, sectionindex) => {
							return (
								<>
									<>
									{
										sectiondata.subtitle.length === 0 ?
										<>
											<h2 className={styles.sectiontitle+" productsectiontitle-"+sectionindex+" font-style-bold font-size-regular color-text-primary"}>{sectiondata.title}</h2>
											{
												sectiondata.title==="DESCRIPTION"?
												<div itemprop="description" className={styles.sectionbody+" "+styles.sectiondescriptionbody} dangerouslySetInnerHTML={{ __html: "<p>"+sectiondata.body.replace(/\n/g,"</p><p>").replace(" is a","</span> is a").replace("Konstrukt","<span>Konstrukt")+"</p>" }} />
												:
												<>
												{
													sectiondata.title==="FEATURES"?
													<ul className={styles.sectionbody +" font-style-bold font-size-small"} dangerouslySetInnerHTML={{ __html: "<li>"+sectiondata.body.replace(/\n/g,"</li><li>")+"</li>" }} />
													:
													<>
													{
														(sectiondata.title==="MATERIAL PREPARATION" || sectiondata.title==="APPLICATION SCHEDULE" )?
															sectiondata.body.indexOf("\n") > 0 ?
																<ol className={styles.sectionbody +" font-style-bold font-size-small"} dangerouslySetInnerHTML={{ __html: "<li>"+sectiondata.body.replace(/\n/g,"</li><li>")+"</li>" }} />
																:
																<div className={styles.sectionbody +" font-style-bold font-size-small"} dangerouslySetInnerHTML={{ __html: "<p>"+sectiondata.body.replace(/\n/g,"</p><p>")+"</p>" }} />
														:
														<div className={styles.sectionbody+" "+styles["sectionbody"+sectiondata.title.replace(/ /g,"").toLowerCase()+this.props.productID] +" font-style-bold font-size-small"} dangerouslySetInnerHTML={{ __html: "<p>"+sectiondata.body.replace(/\n/g,"</p><p>")+"</p>" }} />
													}
													</>

												}</>
											}
										</>
										:
										<>
											{this.state.productdata.info[sectionindex].title !== this.state.productdata.info[sectionindex-1].title &&
											<h2 className={styles.sectiontitle+" productsectiontitle-"+sectionindex+" font-style-bold font-size-regular color-text-primary"}>{sectiondata.title}</h2>
											}
											<strong className={styles.sectionsubtitle+" font-style-bold font-size-regular"}>{sectiondata.subtitle}</strong>
											<div className={styles.sectionbody+" "+styles.sectiondescriptionbody} dangerouslySetInnerHTML={{ __html: "<p>"+sectiondata.body.replace(/\n/g,"</p><p>").replace(" is a","</span> is a").replace("Konstrukt","<span>Konstrukt").replace(/<p>For /g,"<p style='padding-left: 1.5em;'><span style='float: left; margin-left: -1em;'>&bull;</span>For ")+"</p>" }} />
										</>
									}
									</>
								  	{
								  		(sectionindex === 0 && this.state.technicaldata) && (
								  			this.state.technicaldata.details.length > 0 && (
									  			<>
													<h2 className={styles.sectiontitle+" font-style-bold font-size-regular color-text-primary"}>Technical Data</h2>
													<div className={styles.techarea+" font-size-small "+(this.state.technicaldata.variants.length > 1?styles.techareavariantstwo:styles.techareavariantsnone)}>
									  				{
									  					this.state.technicaldata.variants.length > 1 &&  (
									  						<>
																<div className={styles.techheader+" font-style-bold "+styles.techlabel}>&nbsp;</div>
										  						{
														  			this.state.technicaldata.variants.map((techvardata, techvarindex) => {
																		return (
																			<div className={styles.techheader+" font-style-bold "+styles.techcell} dangerouslySetInnerHTML={{ __html: techvardata.name.replace("\n","<br/>") }} />
																		)
																	})
																}
															</>
														)
													}
									  				{
											  			this.state.technicaldata.details.map((techdata, techindex) => {
															return (
																<>
																	{techdata.idx === 0 && <div className={styles.techlabel+" font-style-bold"} dangerouslySetInnerHTML={{ __html: techdata.name }} />}
																	<div className={styles.techcell} dangerouslySetInnerHTML={{ __html:
																		((techdata.name === "Dry Time") && (this.props.productID !== "102")) ?
																			techdata.info.indexOf("*") > 0?
																				techdata.info.replace("**","* ").replace("*","<span>*").replace(/\n/g,"<br/>")+"<br/>** Measured at 25&deg;C and 50% relative humidity</span>"
																				:
																				techdata.info.replace(/\n/g,"<br/>")+"<br/><span>** Measured at 25&deg;C and 50% relative humidity</span>"
																			:
																			((techdata.name === "Theoretical Coverage") && (this.props.productID !== "76" && this.props.productID !== "80")) ?
																				techdata.info.indexOf("*") > 0?
																				techdata.info.replace("*","<span>*").replace(/\n/g,"<br/>")+"</span><br/><span>** Depending on surface texture and porosity</span>"
																					:
																					techdata.info.replace(/\n/g,"<br/>")+"<br/><span>* Depending on surface texture and porosity</span>"
																			:
																				techdata.info.indexOf("*") > 0?
																					techdata.info.replace("*","<span>*").replace(/\n/g,"<br/>")+"</span>"
																					:
																					techdata.info.replace(/\n/g,"<br/>")
																		 }} />
																</>
															)
														})
													}
													</div>
												</>
											)
										)
								  	}
								</>
							)
						})
				  	}
					{
						(this.state.colordata) && (
							this.state.colordata.length > 0 && (
								<>
									<h2 className={styles.sectiontitle+" font-style-bold font-size-regular color-text-primary"}>Available Colors</h2>
									<div className={styles.sectionbody+" "+styles.sectiondescriptionbody+" "+styles.sectioncolorbody}>
									{
										this.state.colordata.map((colordata, colorindex) => {
											return (
												<p>{colordata.code+" "+colordata.name}</p>
											)
										})
									}
									</div>
								</>
							)
						)
					}
				  	</div>
				}
				{
					(this.props.nocalc === false) &&
					<>
						<PaintHeader surface={this.state.surfacetext} typeinfo={""} condition={""} />
						{
							(this.props.surfacelist.length > 1) &&
							<div className={"text-centered"}>
								<ControlSelect
										id="detail"
										handleChange={this.handleDetailChange}
										list={this.props.surfacelist}
									/>
							</div>

						}
						{
							this.state.calcdata &&
							<PaintTable
									surfacecalc={this.state.calcdata}
									surfacetext={this.state.surfacetext}
								/>
						}
					</>
				}
				<br/>
				<br/>
			</div>
		);
	}
}

ProductPage.propTypes = {
  nocalc: PropTypes.bool,
  surfacelist: PropTypes.object,
  productID: PropTypes.string,
  pageTitle: PropTypes.string,
}

ProductPage.defaultProps = {
  nocalc:false,
  surfacelist:[],
  productID:``,
  pageTitle:``,
}

export default ProductPage
